<template>
   <div v-if="showVeri">
      <el-dialog title="安全验证" :visible.sync="dialogVisibleVeri" width="30%" :before-close="handleClose">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="手机号码" prop="name">
            <span>15179027575</span>
          </el-form-item>
          <el-form-item label="验证码" prop="code1">
            <div class="item">
              <el-input v-model="ruleForm.code1"></el-input>
              <el-button @click="toSend('15179027575', 1)" :loading="isLoading1" :disabled="isLoading1" size="medium" type="primary">{{ title1 }}</el-button>
            </div>
          </el-form-item>
          <el-form-item label="手机号码" prop="name">
            <span>15107546894</span>
          </el-form-item>
          <el-form-item label="验证码" prop="code2">
            <div class="item">
              <el-input v-model="ruleForm.code2"></el-input>
              <el-button @click="toSend('15107546894', 2)" :loading="isLoading2" :disabled="isLoading2" type="primary">{{ title2 }}</el-button>
            </div>
          </el-form-item>
          <el-form-item label="手机号码" prop="name">
            <span>13429669222</span>
          </el-form-item>
          <el-form-item label="验证码" prop="code3">
            <div class="item">
              <el-input v-model="ruleForm.code3"></el-input>
              <el-button @click="toSend('13429669222', 3)" :loading="isLoading3" :disabled="isLoading3" type="primary">{{ title3 }}</el-button>
            </div>
          </el-form-item>
          <el-form-item v-if="phoneTips" label="验证提示">
            <div class="item" style="color:red;">
              {{phoneTips}}
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props:{
    showVeri:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // showVeri: false,
      dialogVisibleVeri: true,
      isLoading1: false,
      isLoading2: false,
      isLoading3: false,
      time1: 60,
      timer1: null,
      time2: 60,
      timer2: null,
      time3: 60,
      timer3: null,
      title1: '发 送',
      title2: '发 送',
      title3: '发 送',


      ruleForm: {
        code1:'',
        code2:'',
        code3:'',
      },
      rules: {
        code1: [{ required: true, message: '请输入验证码', trigger: 'blur' } ],
        code2: [{ required: true, message: '请输入验证码', trigger: 'blur' } ],
        code3: [{ required: true, message: '请输入验证码', trigger: 'blur' } ],
      },
      phoneTips:''
    }
  },
  created() {},
  mounted() {},
  methods: {
    handleClose() {
      this.$emit('handleClose')
    },
     // 短信验证-----------start
    toSend(phone, type) {
      let taht = this
      this['timer' + type] = setInterval(function () {
        if (taht['time' + type] == 0) {
          clearInterval(taht['timer' + type])
          taht['isLoading' + type] = false
          taht['title' + type] = '发  送'
        } else {
          if (!taht['isLoading' + type]) {
            taht['isLoading' + type] = true
          }
          taht['time' + type]--
          taht['title' + type] = taht['time' + type] + 'S'
        }
      }, 1000)

      this.$api
        .sendSms({
          phone,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: 'success',
              message: '发送成功!',
            })
          }
        })
    },
    submitForm(formName) {
      if(this.phoneTips){
        this.phoneTips = ''
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api
            .phoneVerification({
              phone_verification:[
                {
                  phone:'15179027575',
                  code:this.ruleForm.code1,
                },
                {
                  phone:'15107546894',
                  code:this.ruleForm.code2,
                },
                {
                  phone:'13429669222',
                  code:this.ruleForm.code3,
                }
              ]
            })
            .then((res) => {
              console.log('res',res)
              if (res.data.code == 0) {
                this.$message({
                  type: 'success',
                  message: '验证成功!',
                })
                // 验证成功的缓存
                this.$localStorage.setItem('veri',{'veri':'ok'},1800)
                this.$emit('veriOk')
                this.$emit('handleClose')
              }else{
                let phone_list = []
                res.data.data.map((item)=>{
                  if(item.pmsg == 0){
                    phone_list.push(item.phone)
                  }
                })
                this.phoneTips = phone_list.join(',') + '未通过验证，请核对'
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-input {
    padding-right: 40px;
  }
}
</style>
