/*
 * @Author: your name
 * @Date: 2022-01-19 16:28:21
 * @LastEditTime: 2022-01-19 17:00:51
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \wnspace-front-back\src\common\ssBookScope.js
 */
//列表里的属性名：eg:project_phase，create_order_time
export default [
    // 'batch',
    'card_number',
    'pwd',
    // 'expiration_day',
    // 'money',
    // 'card_user_quantity',
    // 'film_name',
    // 'movie_name',
    // 'city_str',
    // 'remarks'
]



