/*
 * @Author: your name
 * @Date: 2022-01-04 14:22:10
 * @LastEditTime: 2022-01-19 17:22:57
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \demo\demo6\src\public\export.js
 */
var idTmr;
import tools from '../../src/utils/tools'
//自己定义一个函数transform，在里面写我们的业务逻辑
function transform(table = [], myheaderData = [], mysecond_level_table_header = [], myitemName = [], name, callback) {  //table为表格数据,name为导出文件名,
    //callback为导出完毕回调,方便你知道导出完成了(可根据自己需求决定是否需要)
    //myheaderData为一级表头
    //mysecond_level_table_header为二级表头
    //myitemName为表格每一项数据的属性，便于通过他获取属性值
 
   // 接收传来的值
    let bodyData = table;      //这里对应是表格数据，我们只需要传过来即可
    let headerData = myheaderData;
    let second_level_table_header = mysecond_level_table_header;
    let itemName = myitemName;
    console.log(itemName);


    //dom结构起点
    let tableInnerHTML = '';

    //拼接完全使用thead、tbody、tr、td、th，并且相应的tr、th、td里可以写一些类似colspan(决定占几列)
    //rowspan(决定占几行)的属性、可以用作合并行、合并列等高级操作
    tableInnerHTML += '<thead><tr>';    //头部部分开始拼接！
    headerData.forEach(item => {
        tableInnerHTML += `<th colspan=${item.colspan} rowspan=${item.rowspan}  style='border:solid'>` + item.title + "</th>"
    })
    tableInnerHTML += '</tr>';     //头部部分结束

    if(!tools.isEmpty(second_level_table_header)){
        console.log('循环获取二级表头');
        tableInnerHTML += "<tr>"//循环获取二级表头
        let content = [];
        second_level_table_header.forEach(item => {
            item.forEach(ite => {
                content.push(`<th style='border:solid'>${ite.title}</th>`)
            })
        })
        tableInnerHTML += content.join('')
        tableInnerHTML += '</tr>'
    }



    tableInnerHTML += '<tbody>'//表格主体   //身体部分开始
    // let itemName = ['project_phase', 'create_order_time', 'sale_department', 'salesman', 'enterprise_client_name', 'enterprise_manufacturer_name', 'product_name', 'product_model', 'test_standard_item', 'project_number', 'payer', 'amount_receivable', 'paid_in_amount', 'date_of_payment', 'add_process_card_time', 'add_process_card_people', 'project_manager', 'sample_arrive_time', 'process_card_time', 'process_card_people', 'department', 'laboratory', 'engineer', 'report_preparation_time', 'report_preparation_people', 'report_review_time', 'report_review_people', 'Issue_time', 'Issue_people', 'report_production_time', 'report_production_people', 'cnas', 'cma', 'oenterprise_is_certificate', 'test_cycle', 'completion_time', 'sample_logistics', 'sample_management', 'courier_number', 'offer', 'discount_price', 'remarks', 'is_qualified']//列表里的属性名：eg:project_phase，create_order_time
    let itemArr = [];//容器，用来

    bodyData.forEach((item, index) => {//循环列表数据，获取每一项的属性名
        itemArr.push("<tr><td align='center' style='border:solid'>" + (index + 1) + "</td>");//行和序号
        // console.log(item);
        itemName.forEach(item2 => {//循环属性名，找出每个对应的属性值-item[item2]，且给他们加上td标签
            // console.log(item[item2]);
            itemArr.push("<td align='center' style='border:solid'>" + item[item2] + "</td>")
        });
        itemArr.push("</tr>");
    });
    tableInnerHTML += itemArr.join("");//dom结构由数组转为字符串，以进行页面渲染
  
    
    tableInnerHTML += '</tbody>';    //身体结束
    //------------OK，到此为止拼接工作做完，也就是基本的数据已经被拼接成表格了--------------------
    //tip开始(下面还有个tip结束的位置)
    /*-------从tip开始到tip结束的过程是判断浏览器类型步骤，做兼容性处理！对于你来说你完全可以不用
             深入理解这里面的逻辑，直接复制到自己的项目里去，不会存在任何浏览器兼容性的问题！*/
    function getExplorer() {
        var explorer = window.navigator.userAgent;
        if (explorer.indexOf('MSIE') >= 0) {
            return 'ie';        // ie
        } else if (explorer.indexOf('Firefox') >= 0) {
            return 'Firefox';   // firefox
        } else if (explorer.indexOf('Chrome') >= 0) {
            return 'Chrome';    // Chrome
        } else if (explorer.indexOf('Opera') >= 0) {
            return 'Opera';     // Opera
        } else if (explorer.indexOf('Safari') >= 0) {
            return 'Safari';    // Safari
        };
    };

    if (getExplorer() !== 'Safari' && name.substr(-1, 4) !== '.xls') {
        name += '.xls';
    }
    if (getExplorer() === 'ie') {
        var curTbl = table;
        var oXL = new ActiveXObject('Excel.Application');
        var oWB = oXL.Workbooks.Add();
        var xlsheet = oWB.Worksheets(1);
        var sel = document.body.createTextRange();
        sel.moveToElementText(curTbl);
        sel.select();
        sel.execCommand('Copy');
        xlsheet.Paste();
        oXL.Visible = true;
        try {
            var fname = oXL.Application.GetSaveAsFilename('Excel.xls', 'Excel Spreadsheets (*.xls), *.xls');
        } catch (e) {
            print('Nested catch caught ' + e);
        } finally {
            oWB.SaveAs(fname);
            // oWB.Close(savechanges = false);
            oXL.Quit();
            oXL = null;
            idTmr = setInterval(Cleanup(), 1);
        }
    } else {
        tableToExcel(tableInnerHTML, name, callback);   /*在这调用下面的一个方法，传入拼接完成
                                        	  的表格，文件名，回调函数。该方法是干嘛的请往下看*/
    }
    //tip结束
}   //此括号结束，我们自己封装的transform方法也结束了！90%的逻辑完成了！

/*下面的两个函数对于你来说你也完全不用深入理解里面的逻辑，你只要知道，他是在帮助你做转换，帮助你
  将拼接好的HTML字符串模板真正地转换并且输出到Excel里面去，直接当成固定书写方法，直接拿来用即可*/
function Cleanup() {
    window.clearInterval(idTmr);
}
let tableToExcel = (function () {
    let template = '<html><head><meta charset="UTF-8"></head><body><table>{table}</table></body></html>';
    let format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; });
    };
    return function (table, name, callback) {
        let ctx = { worksheet: name || 'Worksheet', table: table };
        let blob = new Blob([format(template, ctx)]);
        let a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = name;     //这里这个name就是对应的文件名！
        a.click();
        a.remove();
        callback('success');    /*这里调用我们自己传入的回调方法，这样导出Excel完成后你就能
        						 在外面知道导出完毕，并且再往下做自己其他的逻辑*/
    };
})();
export default transform;    //导出自己封装的transform方法
