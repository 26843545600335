import Vue from 'vue'
let Base64 = require('js-base64').Base64   //base64加密 解码插件
// 工具
const TOOLS = {
    isEmpty(obj) {
        if (obj == '') return true
        if (obj == null) return true
        if (obj.length > 0) return false
        if (obj.length === 0) return true
        if (typeof obj !== 'object') return true
        for (var key in obj) {
            if (hasOwnProperty.call(obj, key)) return false
        }
        return true
    },
    strToDate(dateString) {
        if (dateString) {
            var date = new Date(dateString.replace(/-/, "/"))
            return date;
        }
    },
    getWeekday(first, last) {
        //计算工作日方法：遍历这两个日期区间的每一个日期，获取他的getDay()

        //分别获取first和last的毫秒数(时间戳)
        console.log('first,last', first, last)
        first = first.getTime();
        last = last.getTime();
        let d1, d2, r, d
        // i += 24 * 3600 * 1000，每天的毫秒总合
        var count = 0;
        for (var i = first; i <= last; i += 24 * 3600 * 1000) {
            d = new Date(i);
            if (d.getDay() >= 1 && d.getDay() <= 5) {
                count++;
            }
        }
        console.log('返回计算的工作日为：', count)
        return count != 0 ? count : '';
    },

    // d1 = new Date(2016, 10, 1);
    //     d2 = new Date(2016, 11, 1);
    // //调用函数，传入这两个日期，得到工作日的天数
    //     r = getWeekday(d1, d2);
    // console.log(r);

    // 操作权限判断 str是当前页面的路由+"del/edit/..."，arr是所有权限数据，index是路由值,isChecked是否有此权限
    isJump(str) {
        console.log('当前判断的权限路由',str)
        let arr = Lockr.get('menu_list_judge')
        for (let index = 0; index < arr.length; index++) {
            // console.log(str,arr[index].index,arr[index].index == str)
            if (arr[index].index == str) {//判断所有数据中有没有index与str匹配的（找这条操作便于判断isChecked），如果有且他被赋予权限isChecked，就接着走
                console.log('包含该路由')
                if (arr[index].isChecked) {
                    console.log('有权限')
                    return true
                } else {
                    console.log('无权限')
                    return false
                }
            }
        }
        return false
    },
    hideTags(t) {
        t.style.display = "none"
    },

    // base64解码
    b64Decode(str) {
        console.log('解码参数', str)
        console.log('解码结果', Base64.decode(str))
        return Base64.decode(str)
    },
    // base64加密
    b64Encode(str) {
        console.log('加密参数', str)
        return Base64.encode(str)
    },

}

Vue.prototype.$tools = TOOLS
export default TOOLS