
/*
 * @Author: your name
 * @Date: 2022-01-17 11:50:20
 * @LastEditTime: 2022-01-19 16:29:10
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \wnspace-front-back\public\tableHeader.js
 */

// 样品台账表头
export default [
    {
        title: '序号',
        colspan: 1,
        rowspan: 1
    },
    // {
    //     title: '批次',
    //     colspan: 1,
    //     rowspan: 1
    // },
    {
        title: '卡号',
        colspan: 1,
        rowspan: 1
    }
    , {
        title: '密码',
        colspan: 1,
        rowspan: 1
    }, 
    // {
    //     title: '有效期',
    //     colspan: 1,
    //     rowspan: 1
    // },
    // {
    //     title: '面额',
    //     colspan: 1,
    //     rowspan: 1
    // },
    // , {
    //     title: '最大领用数量',
    //     colspan: 1,
    //     rowspan: 1
    // },
    //  {
    //     title: '限定电影',
    //     colspan: 1,
    //     rowspan: 1
    // },
    // {
    //     title: '限定影城',
    //     colspan: 1,
    //     rowspan: 1
    // },
    // {
    //     title: '限定影城',
    //     colspan: 1,
    //     rowspan: 1
    // },
    // {
    //     title: '城市',
    //     colspan: 1,
    //     rowspan: 1
    // },
    // {
    //     title: '备注',
    //     colspan: 1,
    //     rowspan: 1
    // }
    
]